import React from 'react';
import { graphql, Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import { SEO } from '../components/SEO';
import styled from '../styled';
import { Integration } from '../types/Integration';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { CookieBar } from '../components/CookieBar';
import withRoot from '../withRoot';
import { SignupButtonWithChoice } from '../components/SignupButton';
import { ArrowDown, ArrowRightCircle } from 'react-feather';
import { ComparisonTableContent } from '../components/ComparisonTable';
import { SalesCallout } from '../components/SalesCallout';
import { IArticleDetail } from '../services/helpscout';
import {
  AffiliateDashboardSection,
  HeatmapSection,
  RevenueAttributionSection,
  RevisionsSection
} from '../components/Section';

type IContext = {
  integration: Integration;
  article?: IArticleDetail;
};

const LogoContainer = styled('div')`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #f5f4f4;
  background-color: #fff;
  nkbox-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(4)}px;

  svg {
    display: block;
    margin: 24px auto;
  }
`;

const HeaderWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(18)}px 0 ${(p) => p.theme.spacing(8)}px;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(9)}px 0 ${(p) => p.theme.spacing(8)}px;
  }
`;

const Header = styled('div')`
  max-width: 800px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(2)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const ExternalLinkSpan = styled('span')`
  display: block;
  color: ${(p) => p.theme.palette.primary.main};
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  svg {
    position: relative;
    top: 2px;
    margin-left: 4px;
  }
`;

const ExternalLink = ({ href }: { href: string }) => {
  return (
    <ExternalLinkSpan>
      <Link to={href}>
        https://affilimate.com{href}
        <ArrowRightCircle size={16} />
      </Link>
    </ExternalLinkSpan>
  );
};

export const pageQuery = graphql`
  query($slug: String) {
    helpScoutArticle(slug: { eq: $slug }) {
      name
      slug
      text
    }
  }
`;

const Template = ({
  pageContext,
  data
}: {
  pageContext: IContext;
  data: { helpScoutArticle: IArticleDetail };
}) => {
  const { integration } = pageContext;
  const { helpScoutArticle: article } = data;
  const isApi = integration.methods.indexOf('api') !== -1;

  return (
    <>
      <>
        <Navigation />
        <SEO
          title={`${integration.name} ${isApi ? 'API ' : ''}Integration`}
          pathname={`/integrations/${integration.slug.current}/`}
        />
        <main>
          <HeaderWrapper>
            <Header>
              <LogoContainer>
                {integration.slug.current === 'pepperjam' ? (
                  <a href="https://pepperjam.com" target="_blank">
                    <img
                      alt={`${integration.name} integration`}
                      src={integration.logo.image.src}
                      title={`${integration.name} integration`}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '60px',
                        margin: '0 auto'
                      }}
                    />
                  </a>
                ) : (
                  <img
                    alt={`${integration.name} integration`}
                    title={`${integration.name} integration`}
                    src={integration.logo.image.src}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '60px',
                      margin: '0 auto'
                    }}
                  />
                )}
                <ArrowDown size={24} />
                <img
                  src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
                  style={{ maxWidth: '126px', margin: '0 auto 24px' }}
                />
              </LogoContainer>
              <div>
                <h1>
                  {integration.name} {isApi && 'API'} Integration
                </h1>
                {article && (
                  <ExternalLink
                    href={`/docs/${integration.slug.current}-integration/`}
                  />
                )}
                <Typography
                  variant="body1"
                  component="p"
                  style={{ lineHeight: '32px' }}
                  gutterBottom
                >
                  See all your affiliate commissions from {integration.name} and
                  other networks in one, unified dashboard.{' '}
                  {integration.features.indexOf('smartLabels') !== -1 &&
                    `Track the exact page, link, and on-page element that drives each ${integration.name} conversion on your website.`}
                </Typography>
                <br />
                <SignupButtonWithChoice
                  text={
                    <>
                      <ArrowRightCircle size={20} /> &nbsp; Create your
                      dashboard
                    </>
                  }
                />
              </div>
            </Header>
          </HeaderWrapper>
          <div
            style={{
              textAlign: 'center',
              margin: '96px auto 48px',
              maxWidth: '800px'
            }}
          >
            <h2>
              Finally, an analytics suite designed for affiliate publishers
            </h2>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Affilimate aggregates all your affiliate commissions in one place,
              and then adds the missing data you need to maximize your affiliate
              revenue from every piece of content.
            </Typography>
          </div>
          <AffiliateDashboardSection orientation="right" />
          <RevenueAttributionSection orientation="right" />
          <HeatmapSection orientation="right" />
          <RevisionsSection orientation="right" />
          <ComparisonTableContent />
          <SalesCallout />
        </main>
        <Footer />
        <CookieBar />
      </>
    </>
  );
};

export default withRoot(Template);
