import React from 'react';
import { Check, Cross } from '../components/Check';
import { Typography, Paper } from '@material-ui/core';
import styled from '../styled';
import { css } from '@emotion/core';

export const ComparisonTableContent = () => (
  <>
    <div style={{ textAlign: 'center', marginTop: '48px', padding: '12px' }}>
      <Typography
        component="h2"
        variant="h5"
        style={{ fontWeight: 700 }}
        gutterBottom
      >
        What sets Affilimate apart from other affiliate tools?
      </Typography>
      <Typography component="p" variant="body1" gutterBottom>
        Here's why Affilimate is different than any affiliate tool you've used
        before.
      </Typography>
    </div>
    <CheckListContainer>
      <CheckContainer>
        <CheckItem>
          <IconContainer>
            <Cross />
          </IconContainer>
          <div>
            <strong>Not a WordPress plugin</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              Eliminates the performance, security, and functionality
              limitations that come with plugins.
            </Typography>
          </div>
        </CheckItem>
        <CheckItem>
          <IconContainer>
            <Cross />
          </IconContainer>
          <div>
            <strong>Not a link cloaker</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              Keep whatever cloaker or shortener you like. We won't make you do
              manual work before you get tracking.
            </Typography>
          </div>
        </CheckItem>
        <CheckItem>
          <IconContainer>
            <Cross />
          </IconContainer>
          <div>
            <strong>Not a single-solution tool</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              Most tools give you just one piece of the picture, like heatmaps,
              click-tracking, or commission reporting.
            </Typography>
          </div>
        </CheckItem>
      </CheckContainer>
      <CheckContainer>
        <CheckItem>
          <IconContainer>
            <Check />
          </IconContainer>
          <div>
            <strong>Cloud-based</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              Our massive data-crunching happens on thousands of servers &mdash;
              without impacting yours.
            </Typography>
          </div>
        </CheckItem>
        <CheckItem>
          <IconContainer>
            <Check />
          </IconContainer>
          <div>
            <strong>Automatically tracks all your links</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              Don't worry if you have short links, long links, or a mixture.
              We'll find them for you automatically.
            </Typography>
          </div>
        </CheckItem>
        <CheckItem>
          <IconContainer>
            <Check />
          </IconContainer>
          <div>
            <strong>Complete suite of tools</strong>
            <br />
            <Typography color="textSecondary" variant="body2">
              We believe the real insights comes from a tight integration of
              analytics across your entire affiliate conversion funnel.
            </Typography>
          </div>
        </CheckItem>
      </CheckContainer>
    </CheckListContainer>
  </>
);

export const IconContainer = styled('div')`
  width: 24px;
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

export const CheckListContainer = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  max-width: 800px;
  margin: ${(p) => p.theme.spacing(4)}px auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const CheckItem = styled('span')`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.body2.fontSize}px;
    line-height: ${theme.typography.body2.lineHeight};
    text-align: left;
    margin-bottom: ${theme.spacing(2)}px;

    svg {
      position: relative;
      margin-right: 6px;
      top: ${theme.spacing(1)}px;
    }
  `};
`;

export const CheckContainer = styled('div')`
  ${() => css`
    text-align: left;
    padding: 12px 18px;
  `};
`;
