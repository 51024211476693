import React from 'react';
import styled from '../styled';
import { Button, Typography } from '@material-ui/core';
import { sendCtaClickEvent } from '../tracking';
import { CALENDAR_LINK } from '../constants/demo-link';
import { ArrowRightCircle } from 'react-feather';
import { COLORS } from '../themes/color';

const Wrapper = styled('div')`
  background-color: ${COLORS.PAPER};
  color: #000;
  text-align: center;
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px
    ${(p) => p.theme.spacing(4)}px;
  max-width: 1170px;
  margin: ${(p) => p.theme.spacing(3)}px auto;

  p {
    margin: ${(p) => p.theme.spacing(3)}px auto;
    max-width: 600px;
  }

  .contact-us {
    color: #9e879b;
  }
`;

export const SalesCallout = () => {
  return (
    <>
      <Wrapper>
        <Typography
          variant="h4"
          component="p"
          style={{ fontWeight: 900 }}
          paragraph
        >
          See how media companies like yours are achieving commerce clarity
        </Typography>
        <Typography variant="body1" component="p">
          Book a 1:1 demo and see how Affilimate has helped hundreds of
          publishers across 1000+ sites scale their affiliate revenue and
          operations.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href={CALENDAR_LINK}
          target="_blank"
          style={{
            borderWidth: '2px',
            letterSpacing: '0.2px',
            fontWeight: 700,
            marginTop: '8px'
          }}
          size="large"
          onClick={() => {
            sendCtaClickEvent({ label: 'demo' });
          }}
        >
          Book a 20-minute demo &nbsp; <ArrowRightCircle size={17} />
        </Button>
        <br />
        <br />
      </Wrapper>
    </>
  );
};
