import { Check as IconCheck, X as IconX, Minus } from 'react-feather';
import React from 'react';
import styled from '../../styled';

const X = ({ className }: { className?: string }) => {
  return <IconCheck className={className} />;
};

export const Check = styled(X)`
  color: ${(p) => p.theme.custom.colors.success.main};
`;

const Y = ({ className }: { className?: string }) => {
  return <IconX className={className} />;
};

export const Cross = styled(Y)`
  color: ${(p) => p.theme.palette.grey.A700};
`;

export const Dash = styled(Minus)`
  color: ${(p) => p.theme.palette.grey[300]};
`;
